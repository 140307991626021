// =====================================================
// Styles for printing (old school PACE customers!)
// - Starter styles
// - General/global styles
// - Home page specific
// - Other pages
// - Weather data
// =====================================================

/* =Starter styles - taken from HTML5 Boilerplate -----------------------------*/
  *,
  *:before,
  *:after {
    // background: transparent !important;
    // color: #000 !important; /* Black prints faster */
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  // a,
  // a:visited { text-decoration: underline; }

  // a[href]:after {
  //   content: " (" attr(href) ")";
  //   font-size: 60%;
  // }

  // abbr[title]:after { content: " (" attr(title) ")"; }

  /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */

  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

/* = Printing Tables: http://css-discuss.incutio.com/wiki/Printing_Tables-----------------------------*/
  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  // .group:after {
  //   content: "";
  //   display: table;
  //   clear: both;
  // }

  .group:before,
  .group:after {
    content: "";
    display: table;
  }
  .group:after {
    clear: both;
  }

/* =General/global styles -----------------------------*/
  body {
    font-family: Helvetica, Arial, sans-serif;
  }

  #cookieConsentForm,
  #logo,
  #member_link,
  #nav,
  .home_image,
  #footer ul,
  #footer img,
  .pagination {
    display: none;
  }

  #logo_print {
    display: block;
    width: 180px;
    margin: 2rem 0 0 -10pt;
  }

  #footer {
    clear: both;

    #copyright {
      font-size: 10pt;
      margin-top: 8rem;
    }
  }

  // Hide narrow column on subpages
  #journal,
  #testimonials,
  #public,
  #tour,
  #contact,
  #memberedition,
  #search,
  #wiki,
  #members,
  #gallery {
    .narrow_column {
      display: none;
    }
  }

/* =Home page specific -----------------------------*/
  #home {
    .h2morelink {
      display: block;
      font-size: 14pt;
    }

    .narrow_column.last2 {
      display: none;
    }
  }

/* =Other pages -----------------------------*/
  #testimonials {
    .testimonial_wrap {
      border-bottom: 1pt solid #000;
      margin-bottom: 15pt;
    }
  }

  #tour {
    .tour_feature {
      float: left;
      margin: 2em 30px 0 0;

      a[href]:after {
        display: block;
      }
    }

    .clear + p {
      float: none;
      clear: both;
    }
  }

  #contact {
    #contact_form {
      display: none;
    }
  }

  // weather page / table
  #weather-data {
    display: block;
    font-size: 10pt;
    table-layout: fixed;
    width: 100%;

    .weather-table-wrap {
      display: block;
      width: 100%;
    }

    .forecasted-info {
      background: #eee !important;
      display: block;
      padding: 0 10px 10px;
    }

    #weather_ranges {
      border: 1px solid #000;

      th.header {
        background: #eee !important;
        border: 1px solid #000;
        border-width: 1px 1px 2px;
        padding: 5px;
      }

      tr.data-row th,
      tr.data-row td {
        border: 1px solid #000;
        padding: 5px;

        &.alert_red {
          // background: #f50014 !important;
          color: #f50014 !important;
          font-weight: bold;
        }

        &.alert_green {
          // background: #8bc63f !important;
          color: #8bc63f !important;
          font-weight: bold;
        }

        &.alert_yellow {
          color: #7f6e19 !important;
          font-weight: bold;
        }
      }
    }
  }

  #weather_forecast5 {
    border: 2px solid #000;
    border-collapse: collapse;
    font-size: 10pt;
    width: 100%;

    .weather_col_20 {
      display: table-column;
      float: left;
      width: 20%;
    }

    .weather_col_16 {
      display: table-column;
      float: left;
      width: 16%;
    }

    .th {
      font-weight: bold;
      // text-align: center;
    }

    p.cell {
      border: 1px solid #000;
      margin: 0;
      min-height: 24px;
      padding: 5px;

      &-tall {
        min-height: 64px;
      }
    }

    .td {
      p.cell {
        text-align: center;
      }

      .alert_green {
        color: #8bc63f !important;
        font-weight: bold;
      }

      .alert_red {
        color: #f50014 !important;
        font-weight: bold;
      }

      .alert_yellow {
        color: #7f6e19 !important;
        font-weight: bold;
      }
    }
  }

  #gallery {
    #gallery-wrap {
      clear: both;
    }

    .gallery-block {
      float: left;
      margin: 0 15px 20px 0;
      padding: 0 0 30px;
      width: 22%;

      &.one {
        clear: left;
      }
      &:nth-child(4n) {
        margin-right: 0;
      }

      h2 {
        font-size: 14pt;
        font-weight: normal;
        line-height: 1.2;
      }

      p {
        font-size: 12pt;
      }
    }
  }

/* = Weather data -----------------------------*/
  .weather-data {
    border: 2px solid #000;
    font-size: 12px;
    width: 100%;

    th,
    td {
      border: 1px solid #000;
      padding: 5px;
      text-align: center;

      &.alert_red {
        background-color: red;
        color: #fff;
      }

      &.alert_green {
        background-color: green;
        color: #fff;
      }

      &.alert_yellow {
        background-color: yellow;
        color: #000;
      }
    }

  }

  .pdf-break {
    page-break-before: always;
  }

  .forecasted-info {
    margin-top: 30px;
    // page-break-before: always;

    p strong {
      color: red;
    }
  }

  .forecast-data {
    // border: 1px solid #000;
    // page-break-before: always;
    width: 700px;

    .data-column-1 {
      display: inline-block;
      float: left;
      margin: 0;
      vertical-align: top;
      width: 130px;

      .cell {
        border: 1px solid #000;
        border-width: 1px 0 0 1px;
        font-weight: bold;
        margin: 0;
        padding: 5px;

        &.last {
          border-bottom: 1px solid #000;
          height: 100px;
        }
      }
    }

    .data-column-2 {
      display: inline-block;
      float: left;
      margin: 0;
      vertical-align: top;
      width: 550px;

      .cell {
        // border-right: 1px solid #000;
        display: inline-block;
        margin: 0 0 0 -4px;
        vertical-align: top;
        width: 90px;

        &.column-1 {
          margin: 0;
        }
        // &.column-2 { border-right: 0; }
        // &.column-3 { border-right: 0; }
        // &.column-4 { border-right: 0; }
        &.column-5 {
          border-right: 1px solid #000;
        }

        .cell-inner {
          border: 1px solid #000;
          border-width: 1px 0 0 1px;
          padding: 5px;
          text-align: center;

          &.last {
            border-bottom: 1px solid #000;
            height: 100px;
          }
        }
      }
    }

    .alert_red {
      background-color: red;
      color: #fff;
    }

    .alert_green {
      background-color: green;
      color: #fff;
    }

    .alert_yellow {
      background-color: yellow;
      color: #000;
    }
  }
